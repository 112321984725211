import React from "react"
import Layout from "../components/layout"
import { ContentSection, BannerSection, More } from "../components/aboutUs"
import SEO from "../components/seo"

const Products = props => {
  return (
    <Layout {...props}>
      <SEO />
      <div className="about-us-page">
        <ContentSection />
        <BannerSection />
        <More />
      </div>
    </Layout>
  )
}

export default Products
